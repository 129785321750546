// State
export const state = () => ({
	loading: false,
	companyBankAccount: []
})

// Actions
export const actions = {
	// Get company bank account
	async getCompanyBankPlayerAccount({ commit }, params) {
		this.commit('settings/setCancelToken', { companyBankAcount: this.$axios.CancelToken.source() })
		commit('setLoading', true)
		try {
			await this.$axios.get('/company-bank-account', {
				params,
				cancelToken: this.state.settings.cancelToken.companyBankAcount.token
			}).then((response) => {
				commit('setCompanyBankAccount', response.data)
			})
			commit('setLoading', false)
		} catch (error) {
			commit('setLoading', false)
		}
	},
}

// Mutations
export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	setCompanyBankAccount: (state, v) => {
		const filter = v.sort((a, b) => {
			if (a.currency < b.currency) {
				return -1
			} else if (a.currency > b.currency) {
				return 1
			} else {
				return 0
			}
		})
		state.companyBankAccount = filter
	},
}

// Getters
export const getters = {
	companyBankAccount: state => state.companyBankAccount,
	loading: state => state.loading
}