export const state = () => ({
	loading: false,
	agentFloatingIcons: {},
})

export const actions = {
	async agentFloatingIcons({ commit }) {
		commit('setLoading', true)
		try {
			await this.$axios
				.get('/agents/icons')
				.then((response) => {
					commit('agentFloatingIcons', response.data)
					commit('setLoading', false)
				}).catch(() => {
					commit('setLoading', false)
				})
		} catch (error) {
			commit('setLoading', false)
		}
	},
}

export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	agentFloatingIcons: (state, v) => {
		state.agentFloatingIcons = v;
	}
}

export const getters = {
	agentFloatingSupportIcon: (state) => {
		const data = state.agentFloatingIcons
		return data || {};
	},
}
