// State
export const state = () => ({
	loading: false,
	gameTypes: [],
	betHistory: {
		"total": 0,
		"totalPages": 0,
		"data": [],
		"total_overall_bet_count": 0,
		"total_overall_bet_amount": 0,
		"total_overall_turnover_amount": 0,
		"total_overall_win_loss_amount": 0
	}
})

// Actions
export const actions = {
	// Get Game Type
	async getGameType({ commit }) {
		commit('setLoading', true)
		this.commit('settings/setCancelToken', { gameType: this.$axios.CancelToken.source() })
		await this.$axios
			.get('/pm/game-types', { cancelToken: this.state.settings.cancelToken.gameType.token })
			.then((response) => {
				commit('setGameType', response.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
	},
	// Get bet history
	async getBetHistory({ commit }, params) {
		commit('setLoading', true)
		const noneReferenceParams = JSON.parse(JSON.stringify(params))
		if (noneReferenceParams.game_type_uuid && noneReferenceParams?.game_type_uuid?.length) {
			noneReferenceParams.game_type_uuid = JSON.stringify(noneReferenceParams.game_type_uuid)
		} else {
			delete noneReferenceParams.game_type_uuid
		}
		this.commit('settings/setCancelToken', { gameHistory: this.$axios.CancelToken.source() })
		await this.$axios
			.get('/bets/bet-history', { params: noneReferenceParams, cancelToken: this.state.settings.cancelToken.gameHistory.token })
			.then((response) => {
				commit('setBetHistory', response.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
	},
}

// Mutations
export const mutations = {
	setLoading(state, data) {
		state.loading = data
	},
	setGameType(state, data) {
		state.gameTypes = data
	},
	setBetHistory(state, data) {
		state.betHistory = data
	}
}

// Getters
export const getters = {
	gameTypes: state => state.gameTypes,
	betHistory: state => state.betHistory
}