export const globalVar = {
	themes: {
		theme0: {
			value: 'theme-0',
			name: 'Premium 2',
			component: 'Theme0',
			page: 'pages/theme-0',
			layouts: 'layouts/theme-0.vue'
		},
		theme1: {
			value: 'theme-1',
			name: 'Free 2',
			component: 'Theme1',
			page: 'pages/theme-1',
			layouts: 'layouts/theme-1.vue'
		},
		theme2: {
			value: 'theme-2',
			name: 'Premium 1',
			component: 'Theme2',
			page: 'pages/theme-2',
			layouts: 'layouts/theme-2.vue',
			hasSubDomainName: false
		},
		theme3: {
			value: 'theme-3',
			name: 'Free 1',
			component: 'Theme3',
			page: 'pages/theme-3',
			layouts: 'layouts/theme-3.vue'
		},
		theme4: {
			value: 'theme-4',
			name: 'Premium 4',
			component: 'Theme4',
			page: 'pages/theme-4',
			layouts: 'layouts/theme-4.vue'
		},
		noneTheme: {
			value: '',
			name: 'Premium 3',
			component: '',
			page: '',
			layouts: ''
		}
	},
	themeList: [],
	devDomainName: 'cslvault.com'
}

globalVar.themeList = [
	globalVar.themes.theme0.value,
	globalVar.themes.theme1.value,
	globalVar.themes.theme2.value,
	globalVar.themes.theme3.value,
	globalVar.themes.theme4.value
];