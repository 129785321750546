// State
export const state = () => ({
	loading: false,
	banks: [],
	accounts: [],
	created: true,
	cardHolderName: '',
	verifyCurrency: {},
	cancelToken: {}
})

// Actions
export const actions = {
	// Banks
	async banks({ commit, state }, params) {
		try {
			if (state?.cancelToken?.banks) {
				state?.cancelToken?.banks.cancel('')
			}
			commit('setCancelTokens', { banks: this.$axios.CancelToken.source() })
			await this.$axios.get(`/banks`, {
				params,
				cancelToken: state.cancelToken.banks.token
			}).then((response) => {
				commit('banks', response.data)
			})
		} catch (error) {
			return false
		}
	},
	// Player accounts
	async accounts({ commit }, payload) {
		try {
			commit('loading', true)
			if (!this.$cookie.get('token')?.length > 0) {
				commit('loading', false)
				return
			}
			await this.$axios
				.get(`/player-bank-account`, {
					params: payload,
				})
				.then((response) => {
					commit('loading', false)
					commit('accounts', response.data.player_bank_accounts)
					commit('setCardHolderName', response.data.account_holder_name)
					if (response.data.player_bank_accounts.length > 0) {
						this.$cookie.set('hasBank', 'true', {
							path: '/',
							maxAge: 60 * 60 * 3 - 60,
						})
					}
				})
		} catch (error) {
			commit('loading', false)
		}
	},
	async verifyCurrency({ commit }, currency) {
		try {
			commit('loading', true)
			await this.$axios
				.get(`/currency/${currency}`,)
				.then((response) => {

					commit('verifyCurrency', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},
}

// Mutations
export const mutations = {
	loading: (state, type) => {
		state.loading = type
	},
	banks: (state, v) => {
		state.banks = v
	},
	accounts: (state, v) => {
		state.accounts = v
	},
	setCardHolderName: (state, v) => {
		state.cardHolderName = v
	},
	verifyCurrency: (state, v) => {
		state.verifyCurrency = v
	},
	setCancelTokens: (state, data) => {
		const oldData = { ...state.cancelToken }
		state.cancelToken = {
			...oldData, ...data
		}
	}
}

// Getters
export const getters = {
	banks: state => state.banks
}
