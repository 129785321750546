// State
export const state = () => ({
	loading: false,
	bankAccount: {
		account_holder_name: null,
	},
	playerCryptoWallets: []
})

// Actions
export const actions = {
	// Get bank account
	async getBankPlayerAccount({ commit }) {
		commit('setLoading', true)
		try {
			await this.$axios.get('/player-bank-account').then((response) => {
				commit('setBankAccount', response.data)
			})
			commit('setLoading', false)
		} catch (error) {
			commit('setLoading', false)
		}
	},
	// Insert bank account
	async doInsertPlayerBankAccount({ commit, dispatch }, form) {
		commit('setLoading', true)
		return await this.$axios.post('/player-bank-account', form).then((response) => {
			dispatch('getBankPlayerAccount')
			commit('setLoading', false)
			return true
		}).catch(() => {
			commit('setLoading', false)
			return false
		})
	},
	// Update bank account
	async doUpdatePlayerBankAccount({ commit, dispatch }, { form, accountId }) {
		commit('setLoading', true)
		return await this.$axios.put('/player-bank-account/' + accountId, form).then((response) => {
			dispatch('getBankPlayerAccount')
			commit('setLoading', false)
			return true
		}).catch(() => {
			commit('setLoading', false)
			return false
		})
	},
	// Delete bank account
	async doDeletePlayerBankAccount({ commit, dispatch }, accountId) {
		commit('setLoading', true)
		return await this.$axios.delete('/player-bank-account/' + accountId).then((response) => {
			dispatch('getBankPlayerAccount')
			commit('setLoading', false)
			return true
		}).catch(() => {
			commit('setLoading', false)
			return false
		})
	},
	async createPlayerCryptoWallet({ dispatch }, form) {
		const response = await this.$axios.post('/player-crypto-wallet', form).then((response) => {
			dispatch('playerCryptoWallet')
			return true
		}).catch(() => {
			return false
		})
		return response
	},
	async playerCryptoWallet({ commit }, params) {
		await this.$axios.get('/player-crypto-wallet', { params }).then((response) => {
			commit('setPlayerCryptoWallets', response.data)
		}).catch(() => {
			return false;
		})
	},
	async deletePlayerCryptoWallet({ dispatch }, id) {
		const response = await this.$axios.delete(`/player-crypto-wallet/${id}`)
			.then((response) => {
				dispatch('playerCryptoWallet')
				return true;
			}).catch(() => {
				return false;
			})
		return response;
	},
	async updatePlayerCryptoWallet({ dispatch }, form) {
		const id = form.id
		delete form.id

		const response = await this.$axios.put(`/player-crypto-wallet/${id}`, form)
			.then((response) => {
				dispatch('playerCryptoWallet')
				return true
			}).catch(() => {
				return false
			})
		return response
	},
}

// Mutations
export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	setBankAccount: (state, v) => {
		if (v?.player_bank_accounts) {
			v.player_bank_accounts = v.player_bank_accounts.sort((b, a) => a.is_primary - b.is_primary)
		}
		state.bankAccount = v
	},
	setPlayerCryptoWallets: (state, v) => {
		const accounts = v.sort((b, a) => a.is_primary - b.is_primary)
		state.playerCryptoWallets = accounts
	}
}

// Getters
export const getters = {
	bankAccount: state => state.bankAccount,
	loading: state => state.loading,
	primaryBankAccount: state => {
		return state.bankAccount.player_bank_accounts ? state.bankAccount.player_bank_accounts.find(o => o.is_primary === true) : {}
	}
}