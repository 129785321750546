// State
export const state = () => ({
	msg: null,
	showHoldBalance: false,
	displayGetBonusDetail: {},
	displayGetBonusModal: false,
	withdrawalStatus: true,
	displayWithdrawalSocket: false
})

// Actions
export const actions = {
	// default page
	playerBalance({ commit }, data) {
		commit('setData', data)
	},
	successfulDeposit({ commit }, data) {
		commit('setData', data)
	},
	revokeDepositByAdmin({ commit }, data) {
		commit('setData', data)
	},
	successfulDepositByAutoTopup({ commit }, data) {
		commit('setData', data)
	},
	revokeDepositByAutoTopup({ commit }, data) {
		commit('setData', data)
	},
	successfulWithdrawal({ commit }, data) {
		commit('setData', data)
	},
	revokeWithdrawal({ commit }, data) {
		commit('setData', data)
	},
	onHoldPlayerBalance({ commit }, data) {
		commit('setData', data)
	},
	confirmDepositByAdmin({ commit }, data) {
		commit('setData', data)
	},
}

// Mutations
export const mutations = {
	setData(state, type) {
		state.msg = type
	},
	setShowHoldBalance(state, value) {
		state.showHoldBalance = value
	},
	setDisplayGetBonusDetail(state, value) {
		state.displayGetBonusDetail = value
	},
	setDisplayGetBonusModal(state, value) {
		state.displayGetBonusModal = value
	},
	setWithdrawalStatus(state, value) {
		state.withdrawalStatus = value
	},
	setDisplayWithdrawalSocket(state, value) {
		state.displayWithdrawalSocket = value
	},
}