// State
export const state = () => ({
	loading: false,
})

// Actions
export const actions = {
	// Get check withdrawal
	async checkExistWithdrawal({ commit }) {
		this.commit('settings/setCancelToken', { existWithdraw: this.$axios.CancelToken.source() })
		return await this.$axios
			.get('/withdrawals/check/is-exists', {
				cancelToken: this.state.settings.cancelToken.existWithdraw.token
			})
			.then((response) => {
				return response.data
			}).catch(() => {
				return {}
			})
	},
	// Create withdraw
	async createWithdraw({ commit }, form) {
		commit('setLoading', true)
		return await this.$axios
			.post('/withdrawals', form)
			.then((response) => {
				commit('setLoading', false)
				return response.data
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
	},
	// Cancel withdraw
	async cancelWithdraw({ commit }, playerId) {
		commit('setLoading', true)
		return await this.$axios
			.put('/withdrawals/cancel/' + playerId, {})
			.then((response) => {
				commit('setLoading', false)
				return response.data
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
	},
	async createCryptoWithdrawal({ commit }, form) {
		commit('setLoading', true)
		return await this.$axios
			.post('/withdrawals/crypto', form)
			.then((response) => {
				commit('setLoading', false)
				return response.data;
			}).catch(() => {
				commit('setLoading', false)
				return false
			})
	},
}

// Mutations
export const mutations = {
	setLoading(state, data) {
		state.loading = data
	},
}

// Getters
export const getters = {
}