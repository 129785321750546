// State
export const state = () => ({
	language: [],
	isMobile: false,
	maintenance: {},
	supportCountry: [],
	exchangeRate: [],
	loadImageError: {},
	countries: [],
	displayRegisterModal: false,
	isInternational: false,
	exchangeRateLoading: true
})

// Actions
export const actions = {
	// Get exchange rate
	async getExchangeRate({ commit }) {
		commit('setExchangeRateLoading', true)
		await this.$axios
			.get('/exchange-rate/fetch-all')
			.then((response) => {
				commit('setExchangeRate', response.data)
			})
			.catch(() => false)
		commit('setExchangeRateLoading', false)
	},
	// Get support languages
	async getSupportCountry({ commit }) {
		await this.$axios
			.get('/website-setting/support-country')
			.then((response) => {
				const supportCountry = response.data
				commit('setSupportCountry', response.data)
				if (supportCountry.length) {
					this.commit('settings/setCurrency', supportCountry[0]?.currency)
				}
			})
			.catch(() => false)
	},
	// Get languages
	async getLanguage({ commit, rootState }, currency) {
		await this.$axios
			.get(`/languages/currency/${rootState.settings.defaultCurrency}`)
			.then((response) => {
				commit('setLanguage', response.data)
			})
			.catch(() => false)
	},
	// Check maintanance
	async fetchMaintenance({ commit, rootState }) {
		return await this.$axios
			.get(`/website-setting/website-maintenance/${rootState.settings.defaultCurrency}`)
			.then((response) => {
				commit('setMaintenance', response.data)
				return response.data
			})
			.catch(() => false)
	},
	// Set visit website
	async fetchVisitWebsite({ commit }) {
		const _document = document
		await this.$axios
			.get('/visit')
			.then((response) => {
				this.$cookie.set('visit', true, {
					path: '/',
					maxAge: 60 * 60,
				})
				if (response.data.banner_uuid) {
					this.$cookie.set('banner_uuid', response?.data?.banner_uuid)
					this.$cookie.set('banner_url', _document.referrer)
				}
				return true
			})
			.catch(() => false)
	},
	async countries({ commit }) {
		await this.$axios
			.get('/country')
			.then((response) => {
				const sortData = response.data.sort((a, b) => {
					if (a.code2 === this.$cookie.get('country')) return -1;
					if (b.code2 === this.$cookie.get('country')) return 1;
					return 0
				})
				commit('setCountries', sortData)
			})
			.catch(() => false)
	},
	async whiteLabel({ commit }) {
		let path = '/website-setting/white-label-type'

		if (this.$config.PRODUCTION && process.server) {
			path = this.$config.SERVER_URL + path
		}

		const config = {
			headers: {
				origin: `https://${this.$config.DOMAIN}`
			},
		};

		await this.$axios
			.get(path, config)
			.then((response) => {
				this.$cookie.set("wl_type", response?.data?.wl_type)
				commit('whiteLabel', response.data)
			})
			.catch(() => false)
	},
}

// Mutations
export const mutations = {
	setLanguage(state, data) {
		if (data?.length > 0) {
			// set locale only there is no locale in the storage
			if (!this.$cookie.get('locale')) {
				const getDefaultLang = data.find(el => el.is_default)
				if (getDefaultLang) {
					this.$cookie.set("locale", getDefaultLang?.language)
				}
			} else {
				const locale = this.$cookie.get('locale')
				this.$cookie.set('locale', locale)
				/**
				 *
				const getDefaultLang = data.find(el => el.is_default)
				// if the local storage language is not available, use the default language instead
				const checkIfTheCurrentLocaleIsStillAvailable = data.some(el => el.language === locale)
				if (!checkIfTheCurrentLocaleIsStillAvailable && getDefaultLang) {
					this.$cookie.set("locale", getDefaultLang?.language)
				} else if (data?.length && !getDefaultLang) {
					this.$cookie.set("locale", data[0].language)
				}
				 */
			}
		}
		state.language = data
	},
	setIsMobile(state, data) {
		state.isMobile = data
	},
	setMaintenance(state, val) {
		state.maintenance = val
	},
	setSupportCountry(state, val) {
		state.supportCountry = val
	},
	setExchangeRate(state, val) {
		state.exchangeRate = val
	},
	setLoadImageError(state, val) {
		state.loadImageError = val
	},
	setCountries(state, val) {
		state.countries = val
	},
	setDisplayRegisterModal(state, val) {
		state.displayRegisterModal = val
	},
	whiteLabel(state, val) {
		state.isInternational = ~~val?.wl_type === 1
	},
	setExchangeRateLoading(state, val) {
		state.exchangeRateLoading = val
	}
}

// Getters
export const getters = {
	language: state => state.language.filter(lng => lng.is_active),
	maintenance: state => state.maintenance,
	supportCountry: state => state.supportCountry,
	exchangeRate: state => {
		const displayBothRateAndReveredRate = []
		if (state.exchangeRate?.length > 0) {
			state.exchangeRate.forEach(el => {
				// push default currency
				if (el.is_show_lnd) {
					displayBothRateAndReveredRate.push(el)
				}

				// Swap currency and rates with type 2 only.
				if (el.is_show_lnd_reverse) {
					const { rate, from_currency: fCurrency, reversed_rate: rRate } = el;
					const swapEl = {
						...el,
						from_currency: el.to_currency,
						to_currency: fCurrency,
						rate: rRate,
						reversed_rate: rate
					};
					displayBothRateAndReveredRate.push(swapEl)
				}
			})
		}
		return displayBothRateAndReveredRate
	},
	swapAllExchangeRate: state => {
		const displayBothRateAndReveredRate = []
		if (state.exchangeRate?.length > 0) {
			state.exchangeRate.forEach(el => {
				displayBothRateAndReveredRate.push(el)
				const { rate, from_currency: fCurrency, reversed_rate: rRate } = el;
				const swapEl = {
					...el,
					from_currency: el.to_currency,
					to_currency: fCurrency,
					rate: rRate,
					reversed_rate: rate
				};
				displayBothRateAndReveredRate.push(swapEl)

			})
		}
		return displayBothRateAndReveredRate
	},
	loadImageError: state => state.loadImageError,
	countries: state => state.countries
}