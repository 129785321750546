// State
export const state = () => ({
	loading: false,
	menu: [],
	currency: [],
	languages: [],
	language: 'en-US',
	countries: [],
	exchangeRate: [],
	slider: [],
	isLogin: false,
	locale: 'th-TH',
	defaultCurrency: null,
	defaultCountry: null,
	currencyListForCreateBank: [],
	displayPopup: true,
	downloadInterval: true,
	supportCountryAndLanguage: [],
	defaultSupportedLanguage: [],
	changeLanguageLoading: false,
	globalFlag: "GO",
	operationSetting: {},
	logo: {},
	cryptoWalletAddressList: [],
	// cancel token
	cancelToken: {},
})

// Actions
export const actions = {
	// Currency
	async currency({ commit }, params) {
		try {
			await this.$axios.get(`/currency`, { params }).then((response) => {
				commit('setCurrency', response.data)
			})
		} catch (error) {
			return false
		}
	},
	// Get countries - WING CODE
	async countries({ commit }) {
		try {
			await this.$axios.get(`/country`).then((response) => {
				commit('setCountries', response.data)
			})
		} catch (error) {
			return false
		}
	},
	toggleIsLogin({ commit }, status) {
		commit('setIsLogin', status)
	},
	locale({ commit }, lang) {
		commit('locale', lang)
	},
	async getDefaultCurrency({ commit, dispatch }, params) {
		/**
			  How to the default language is set; Sort by the highest to lowest priority.
			1. Highest priority, check if the browser cookie has set the language. Use that language to fetch language strings.
			2. Check supported languages, if only one is available, use that one as the default language.
			3. Get the browser language by navigator.language. Check if the language is matched by some supported languages, use that language as the default.
			4. Find the the language which is active and is default. Use that one as the default language.
			5. If all the above conditions are false, use the first supported languages as the default language.
		 */
		await this.$axios('currency/default', params)
			.then(async (data) => {
				let languageStringLanguage = null;
				const defaultCurrency = data?.headers?.currency
				const defaultCountryCode = data?.headers?.country_code
				const defaultCountry = data?.headers?.country
				const domainType = data?.headers?.domaintype
				/**
				 * Cookies explanation
				 * 1. global_reg is on register screen for default phone number
				 * 2. country is country
				 * 3. country_code is for each country flag
				 */

				// global registration
				this.$cookie.set("global_reg", defaultCountry)
				if (domainType) {
					this.$cookie.set('dT', domainType) // Value: [main, agent]
				}
				// If currency is not exist, than set currency
				if (!this.$cookie.get('currency')) {
					this.$cookie.set('currency', defaultCurrency)
					commit('setDefaultCurrency', defaultCurrency)
				} else {
					commit('setDefaultCurrency', this.$cookie.get('currency'))
				}

				if (!this.$cookie.get('country')) {
					this.$cookie.set('country', defaultCountry)
					commit("setDefaultCountry", defaultCountry)
				}

				if (!this.$cookie.get("country_code")) {
					this.$cookie.set('country_code', defaultCountryCode)
					commit("setGlobalFlag", data?.headers?.country_code)
				} else {
					commit("setGlobalFlag", this.$cookie.get('country_code'))
				}

				let supportedLanguages = data?.data?.supported_languages

				// Get the supported country from supported languages
				const supportedCountryByToken = await dispatch('supportCountryAndLanguage')
				const cookieCountryCode = this.$cookie.get('country_code')
				const cookieCurrency = this.$cookie.get('currency')
				if (supportedCountryByToken?.length) {
					if (this.$cookie.get('currency')) {
						function findMatchSupportedCountryByCurrencyFunc(el) {
							// country_code
							if (cookieCountryCode) {
								if (el.currency_code === cookieCurrency && el.country_code === cookieCountryCode) {
									return true;
								}
								return false;
							} else if (el.currency_code === cookieCurrency) {
								return true;
							}
							return false;
						}
						const findMatchSupportedCountryByCurrency = supportedCountryByToken.find(findMatchSupportedCountryByCurrencyFunc)
						if (findMatchSupportedCountryByCurrency) {
							supportedLanguages = findMatchSupportedCountryByCurrency.supported_languages
						}
					}
				}


				commit("defaultSupportedLanguage", supportedLanguages)

				// Check if locale cookie is existed, then use the cookie one as default
				if (this.$cookie.get('locale')) {
					// Check if locale on cookie is active then just use it, otherwise use the default
					const isCookieLocaleActive = supportedLanguages.find(locale => locale.language === this.$cookie.get('locale') && locale.is_active === true)
					if (isCookieLocaleActive?.is_active) {
						languageStringLanguage = this.$cookie.get('locale');
						commit('locale', this.$cookie.get('locale'))
					}
				}

				// After all the LOGIC above, if system Locale is still not set, then find the default one from supportedLanguages list
				if (!languageStringLanguage && supportedLanguages.length > 0) {
					// If the support language has only One Language, just use that one as default
					if (supportedLanguages.length === 1) {
						this.$cookie.set('locale', supportedLanguages[0].language)
						commit('locale', supportedLanguages[0].language)
						languageStringLanguage = supportedLanguages[0].language
					} else {
						// Check if the browser language is matched to one of the supported languages
						const browserLanguage = navigator?.language
						const browserPrefixLanguageTag = browserLanguage?.substring(0, 2)

						// Find the language which starting with the same browser Prefix Language Tag
						let findMatchBrowserLanguage = null;
						if (browserPrefixLanguageTag) {
							findMatchBrowserLanguage = supportedLanguages.find((el) => {
								if (el.language.includes(browserPrefixLanguageTag) && el.is_active) {
									return true;
								}
								return false;
							})
						}

						// Find the browser Prefix Language is match, then use only browser language
						if (findMatchBrowserLanguage) {
							this.$cookie.set('locale', findMatchBrowserLanguage.language)
							commit('locale', findMatchBrowserLanguage.language)
							languageStringLanguage = findMatchBrowserLanguage.language
						} else {
							// If browser doesn't supported navigator.language, then use the default and active one from BO
							const findIsActiveLanguage = supportedLanguages.find(el => el.is_active && el.is_default)
							if (findIsActiveLanguage) {
								this.$cookie.set('locale', findIsActiveLanguage.language)
								commit('locale', findIsActiveLanguage.language)
								languageStringLanguage = findIsActiveLanguage.language
							} else {
								this.$cookie.set('locale', supportedLanguages[0].language)
								commit('locale', supportedLanguages[0].language)
								languageStringLanguage = supportedLanguages[0].language
							}
						}
					}
				}
				if (languageStringLanguage) {
					dispatch('themes/language-string/fetchLanguageStrings', languageStringLanguage, { root: true })
				}
			})
			.catch(_ => { })
	},
	async currencyForCreateBank({ commit }, params) {
		await this.$axios('/currency', { params })
			.then((data) => {
				commit('setCurrencyListForCreateBank', data.data)
			})
			.catch(_ => { })
	},
	async supportCountryAndLanguage({ commit, state }, params = {}) {
		if (state.supportCountryAndLanguage.length !== 0 && params?.fetch !== true) return state.supportCountryAndLanguage
		const response = await this.$axios
			.get('/country/supported-language')
			.then((response) => {
				if (response.data?.length === 1) {
					this.$cookie.set("country_code", response.data[0]?.country_code)
					commit("setGlobalFlag", response.data[0]?.country_code)
				}
				commit('supportCountryAndLanguage', response.data)
				return response.data
			})
			.catch(() => [])
		return response;
	},
	async operationSetting({ commit, state }, currency) {
		await this.$axios
			.get(`/website-setting/operation-setting/${currency || state.defaultCurrency}`)
			.then((response) => {
				const data = response.data;
				commit("operationSetting", response.data)
				//  execute only inter wl
				if (~~this.$cookie?.get("wl_type") === 1) {
					commit('themes/deposit/depositFlow', {
						deposit_type: data.deposit_type,
						is_bank_transfer_enabled: data?.payment_type_settings?.bank_transfer || false,
						is_third_party_payment_enabled: data?.payment_type_settings?.third_party || false,
						is_crypto_payment_enabled: data?.payment_type_settings?.crypto_currency || false,
						reg_flow: data.reg_flow
					}, { root: true })
				}
			})
			.catch(() => {
				return false
			})

	},
	async getCryptoWalletAddressList({ commit }, params) {
		await this.$axios('/currency/crypto', { params })
			.then((data) => {
				commit('setCryptoWalletAddressList', data.data)
			})
			.catch(_ => { })
	},
}

// Mutations
export const mutations = {
	loading: (state, v) => {
		state.loading = v
	},
	setCurrency: (state, v) => {
		state.currency = v
	},
	setCountries: (state, v) => {
		state.countries = v
	},
	setMenu: (state, v) => {
		const promotion = {
			title: {
				'en-US': 'Promotion',
				'lo-LA': 'ໂປຮໂມຊັນ',
				'th-TH': 'โปรโมชั่น',
			},
			to: '/promotion',
			game_type_code: 'OT',
		};
		state.menu = [...v, promotion]
		// state.menu = v.concat(state.menu)
	},
	setExchangeRate: (state, v) => {
		state.exchangeRate = v
	},
	setIsLogin: (state, v) => {
		state.isLogin = v
	},
	locale: (state, v) => {
		state.locale = v
	},
	setCancelToken: (state, data) => {
		const oldData = { ...state.cancelToken }
		state.cancelToken = {
			...oldData, ...data
		}
	},
	setDefaultCurrency: (state, data) => {
		state.defaultCurrency = data
	},
	setDefaultCountry: (state, data) => {
		state.defaultCountry = data
	},
	setGlobalFlag: (state, data) => {
		state.globalFlag = data
	},
	setCurrencyListForCreateBank: (state, data) => {
		state.currencyListForCreateBank = data
	},
	setDisplayPopup: (state, data) => {
		state.displayPopup = data
	},
	setDownloadInterval: (state, data) => {
		state.downloadInterval = data
	},
	supportCountryAndLanguage: (state, data) => {
		state.supportCountryAndLanguage = data
	},
	defaultSupportedLanguage: (state, data) => {
		state.defaultSupportedLanguage = data
	},
	changeLanguageLoading: (state, data) => {
		state.changeLanguageLoading = data;
	},
	operationSetting: (state, data) => {
		state.operationSetting = data;
	},
	setCryptoWalletAddressList: (state, data) => {
		state.cryptoWalletAddressList = data;
	},
}

// Getters
export const getters = {
	currency: (state) => {
		return state.currency
	},
	cryptoCurrencies: (state) => {
		return state?.currency?.filter((el) => el.type === 2)
	},
	currencyIsExchange: (state) => {
		return state.currency.filter((o) => o.is_exchange)
	},
	countries: (state) => {
		return state.countries
	},
	floatingIcons: (state) => {
		return state.floatingIcons
	},
	language: (state) => {
		return state.language
	},
	languages: (state) => {
		return state.languages.filter((o) => o.is_active)
	},
	exchangeRate: (state) => (from, to) => {
		let filter = {
			rate: 0,
		}
		filter = state.exchangeRate.find(
			(o) => o.from_currency === from && o.to_currency === to
		)
		return filter
	},
}
