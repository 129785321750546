// State
export const state = () => ({
	loading: false,
})

// Actions
export const actions = {
	// Register
	async doRegister({ commit, dispatch }, data) {
		commit('setLoading', true)
		const form = JSON.parse(JSON.stringify(data))
		const token = form.recaptchaToken
		delete form.recaptchaToken
		const rememberMe = form.rememberMe
		delete form.rememberMe


		// check referral types
		const referral = this.$cookie.get('referral')
		const previousUrl = this.$cookie.get('previous_url')
		const bannerCookieId = this.$cookie.get('banner_cookie_id')
		const bannerId = this.$cookie.get('banner_uuid') // This cookie is from /visit route
		const bannerUrl = this.$cookie.get('banner_url') // This cookie is from /visit route
		const locale = this.$cookie.get('locale')

		let referralId = null;
		let referralType = null;
		let referralCurrency = '';

		if (referral) {
			const { type, userId, currency } = referral
			referralId = userId
			referralCurrency = currency
			if (type === 'u') referralType = 'player'
			else if (type === 'a') referralType = 'agent'

		}

		// Logic
		// If the registered currency is not matched with the referral currency, then the player will be normal player
		// If they are matched, the player will be referral player under the player who generated the link;
		const isReferralAndRegisteredCurrencyMatched = referralCurrency?.toLowerCase() === data?.currency?.toLowerCase()


		const headers = {
			'Content-Type': 'Application/json',
			'auth-key': token,
		}
		if (referralId && referralType && isReferralAndRegisteredCurrencyMatched === true) {
			headers.Cookies = `referral_id=${referralId}; referral_type=${referralType}`
		}
		if (previousUrl) {
			headers['X-AFFILIATE-URL'] = previousUrl
		}
		if (bannerCookieId) {
			headers['X-BANNER-ID'] = bannerCookieId
		}
		if (bannerId) {
			headers['X-BANNER-ID'] = bannerId
			if (bannerUrl) {
				headers['X-BANNER-URL'] = bannerUrl
			}
		}
		form.language = locale


		let url = `/players/register`

		if ([2, 3, 4].includes(data.registrationOption)) {
			url = `/players/register-without-bank`
			delete form.bank_id
			delete form.bank_account_number
			delete form.bank_currency

			if (data.registrationOption === 4) {
				delete form.account_holder_name
			}
		}

		delete form.registrationOption
		delete form.country

		return await this.$axios
			.post(url, form, {
				headers
			})
			.then(async (response) => {
				if (response.status === 201) {
					await this.$cookie.set('token', response.headers.token, {
						path: '/',
						maxAge: 60 * 60 * 3 - 60,
					})
					await this.commit('player/setPlayerToken', response.headers.token)

					await this.$cookie.set('player', response.data)
					this.$cookie.set('currency', response.data.currency)
					await this.commit('player/setPlayer', response.data)
					this.$cookie.set('country_code', response?.data?.country_code)

					await this.dispatch('settings/toggleIsLogin', true, { root: true })
					await this.dispatch('player/totalBalance', true, { root: true })
					if (rememberMe) {
						this.$cookie.set('un', response.data.player_id, {
							path: '/',
							maxAge: 60 * 60 * 24 * 30 - 60,
						})
						this.$cookie.set('pn', response.data.phone, {
							path: '/',
							maxAge: 60 * 60 * 24 * 30 - 60,
						})
					}
					// Success
					await commit('setLoading', false)
					this.$toast.success(this.getters['themes/language-string/tl']('registerSuccessful'))
					if (isReferralAndRegisteredCurrencyMatched === true && referralId) {
						await this.$cookie.remove('referral')
					}
					await this.$cookie.remove('previous_url')
					await this.$cookie.remove('banner_cookie_id')
					await this.$cookie.remove('banner_uuid')
					await this.$cookie.remove('banner_url')
					if (this.$cookie.get('banner_id')) {
						this.$cookie.remove('banner_id')
					}
					dispatch('themes/player-bank/getBankPlayerAccount', true, { root: true })
					// this.$router.push('/player/deposits/apply')
					return true;
				}
			})
			.catch((e) => {
				commit('setLoading', false)
			})
	},
	// Get bank account holder name
	async getBankAccountHolderName({ commit, dispatch }, { bankId, accountNo }) {
		return await this.$axios.get(`/players/get-bank-account-info?bank_id=${bankId}&bank_account_number=${accountNo}`).then((response) => {
			return response?.data
		}).catch(() => {
			return ''
		})
	},
	// Reset password
	async doResetPassword({ commit }, form) {
		commit('setLoading', true)
		delete form.confirm_password
		return await this.$axios.put('/players/reset-password', form).then((response) => {
			this.$toast.success(this.getters['themes/language-string/tl']('changePasswordSuccessful'));
			commit('setLoading', false)
			return true
		}).catch(() => {
			commit('setLoading', false)
			return false
		})
	},
	// Update player profile
	async doUpdatePlayerProfile({ commit }, form) {
		commit('setLoading', true)
		const formData = JSON.parse(JSON.stringify(form))
		if (!formData.email) formData.email = null
		const items = formData?.social_info
		for (const idx in items) {
			if (!items[idx].social_media_type || !items[idx].social_media_id) items.splice(idx, 1);
		}
		if (!items?.length) delete formData.social_info
		delete formData.currency
		// form.phone = form.phone.replace('+', '')
		if (formData.date_of_birth) {
			formData.date_of_birth = this.$moment(form?.date_of_birth).format('YYYY-MM-DD')
		} else {
			formData.date_of_birth = null
		}
		return await this.$axios.put('/players', formData).then((response) => {
			this.$toast.success(this.getters['themes/language-string/tl']('saveChangeSuccessfull'));
			this.dispatch('player/profile')
			commit('setLoading', false)
			return true
		}).catch(() => {
			commit('setLoading', false)
			return false
		})
	},
	// Forgot password
	async doDeleteSocial({ commit }, socialId) {
		return await this.$axios.delete('/players/delete-social-media/' + socialId).then((response) => {
			this.$toast.success(this.getters['themes/language-string/tl']('deleteSocialSuccessful'));
			return true
		}).catch(() => {
			return false
		})
	},
	// Messages
	async getMessage({ commit }, params) {

		commit('setLoading', true)
		this.commit('settings/setCancelToken', { playerMessage: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/player-messages`, { params, cancelToken: this.state.settings.cancelToken.playerMessage.token })
			.then((response) => {
				this.commit('player/setMessages', response.data)
				commit('setLoading', false)
			})
			.catch(() => {
				commit('setLoading', false)
			})
		commit('setLoading', false)
	},
	async checkSuspendRegistration({ _ }, params) {
		const response = await this.$axios
			.get(`/website-setting/suspend-registration/${params}`)
			.then((response) => {
				return response.data
			})
			.catch(() => {
				return null
			})
		return response;
	},
	async checkRegistrationSetting({ _ }, params) {
		const response = await this.$axios
			.get(`/website-setting/registration-setting/${params}`)
			.then((response) => {
				return response.data
			})
			.catch(() => {
				return null
			})
		return response;
	}
}

// Mutations
export const mutations = {
	setLoading(state, data) {
		state.loading = data
	}
}

// Getters
export const getters = {
	loading: state => state.loading
}