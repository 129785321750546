// State
export const state = () => ({
	loading: false,
	paymentTypes: []
})

// Actions
export const actions = {
	// Get payment type
	async getPaymentType({ commit }) {
		commit('setLoading', true)
		await this.$axios
			.get('/payment-types/master')
			.then((response) => {
				commit('setPaymentType', response.data)
				commit('setLoading', false)
			}).catch(() => {
				commit('setLoading', false)
			})
	},
}

// Mutations
export const mutations = {
	setLoading(state, data) {
		state.loading = data
	},
	setPaymentType(state, data) {
		state.paymentTypes = data
	}
}

// Getters
export const getters = {
	paymentTypes: state => state.paymentTypes
}