// State
export const state = () => ({
	promotions: [],
	promotionId: [],
	loading: false,
})

// Actions
export const actions = {
	// get all promotions
	async promotions({ commit }) {
		commit('loading', true)
		if (!this.$cookie.get('token')) {
			// if the clients get promotions without SIGN IN
			delete this.$axios.defaults.headers.common.Authorization;

			await this.$axios
				.get(`${this.$config.API_DOMAIN}/promotions`)
				.then((response) => {
					commit('setPromotions', response.data)
				})
				.catch(() => false)
			commit('loading', false)
		} else {
			// getting promotions for users who have already LOGIN
			await this.$axios
				.get('/promotions')
				.then((response) => {
					commit('setPromotions', response.data)
				})
				.catch(() => false)
			commit('loading', false)
		}
	},
	promotionCode({ commit }, form) {
		commit('loading', true)
		this.$axios
			.get(`/promotion-codes/claim/${form}`)
			.then((response) => {
				if (response) {
					this.$toast.success('Claim successfully')
				}
				commit('loading', false)
			})
			.catch((_) => {
				commit('loading', false)
			})
	},
	async claimNowRolling({ commit }, form) {
		const response = await this.$axios
			.put(`/rolling-reward/rolling/claim`, {})
			.then((response) => {
				return true
			})
			.catch(() => {
				return false
			})
		return response
	}
}

// Mutations
export const mutations = {
	setPromotion: (state, v) => {
		state.promotionId = v
	},
	setPromotions: (state, data) => {
		state.promotions = data
	},
	loading: (state, type) => {
		state.loading = type
	},
}

// Getters
export const getters = {
	promotions: (state) => {
		return state.promotions
	},
}
