// State
export const state = () => ({
	loading: false,
	content: {}
})

// Actions
export const actions = {
	// Get pages
	setCustomContent({ commit }, content) {
		commit('setContent', content)
	},
}

// Mutations
export const mutations = {
	setContent: (state, val) => {
		state.content = val
	},
}

// Getters
export const getters = {
	content: state => state.content
}