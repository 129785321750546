// State
export const state = () => ({
	referFriend: {
		"total": 0,
		"total_commission": 0,
		"total_pages": 0,
		"number_of_friend": 0,
		"available_commission": 0,
		"is_claimable": false,
		"data": []
	},
	loading: false,
	friendList: {}
})

// Actions
export const actions = {
	// get refer friend
	async getReferFriend({ commit }, params) {
		const customParams = JSON.parse(JSON.stringify(params))
		// The start date and end date time should be in 00:00:00 UTC (Timezone +0)
		if (customParams.start_date) {
			const startDate = customParams.start_date.split(' ')[0] // to get only the date
			customParams.start_date = this.$moment(`${startDate} 00:00:00`).format('YYYY-MM-DD HH:mm:ss')
		}
		if (customParams.end_date) {
			const endDate = customParams.end_date.split(' ')[0] // to get only the date
			customParams.end_date = this.$moment(`${endDate} 23:59:59`).add(1, 'second').format('YYYY-MM-DD HH:mm:ss')
		}
		if (!customParams.sort || customParams?.sort?.includes('created_at')) {
			customParams.sort = 'claim_time|desc'
		}
		commit('setLoading', true)
		this.commit('settings/setCancelToken', { inviteAndEarn: this.$axios.CancelToken.source() })
		await this.$axios
			.get('/invite-and-earn', { params: customParams, cancelToken: this.state.settings.cancelToken.inviteAndEarn.token })
			.then((response) => {
				commit('setReferFriend', response.data)
				commit('setLoading', false)
			})
			.catch(() => {
				commit('setLoading', false)
			})
	},
	// get refer friend
	async friendList({ commit }, params) {
		commit('setLoading', true)
		const customParams = JSON.parse(JSON.stringify(params))
		// The start date and end date time should be in 00:00:00 UTC (Timezone +0)
		if (customParams.start_date) {
			const startDate = customParams.start_date.split(' ')[0] // to get only the date
			customParams.start_date = this.$moment(`${startDate} 00:00:00`).format('YYYY-MM-DD HH:mm:ss')
		}
		if (customParams.end_date) {
			const endDate = customParams.end_date.split(' ')[0] // to get only the date
			customParams.end_date = this.$moment(`${endDate} 23:59:59`).add(1, 'second').format('YYYY-MM-DD HH:mm:ss')
		}
		if (!customParams.sort) {
			customParams.sort = 'created_at|desc'
		}
		await this.$axios
			.get('/reference/friends-list', { params: customParams })
			.then((response) => {
				commit('friendList', response.data)
				commit('setLoading', false)
			})
			.catch(() => {
				commit('setLoading', false)
			})
	},
	// get refer friend
	async doClaimCommission({ commit }) {
		return await this.$axios
			.put('/invite-and-earn/referral/claim', {})
			.then((response) => {
				return response
			})
			.catch(() => {
				return false
			})
	},
}

// Mutations
export const mutations = {
	setReferFriend: (state, val) => {
		state.referFriend = val
	},
	friendList: (state, val) => {
		state.friendList = val
	},
	setLoading: (state, val) => {
		state.loading = val
	},
}

// Getters
export const getters = {
	referFriend: (state) => {
		return state.referFriend
	},
	friendList: (state) => {
		return state.friendList
	}
}
