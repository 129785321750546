export const state = () => ({
	currentSubmenuIndex: null,
})

export const actions = {
	submenuIndex({ commit }, index) {
		commit('setCurrentSubmenuIndex', index)
	},
}

export const mutations = {
	setCurrentSubmenuIndex: (state, index) => {
		state.currentSubmenuIndex = index
	},
}